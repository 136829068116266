import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home2/home2.module').then( m => m.Home2PageModule)},
  {
    path: 'week',
    loadChildren: () => import('./week/week.module').then( m => m.WeekPageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./home2/home2.module').then( m => m.Home2PageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
