import { DataProvider } from './providers/data';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from 'angularfire2';


import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { AngularFirestoreModule } from 'angularfire2/firestore';
import { CasualtyComponent } from "./casualty/casualty.component";






var DEBUG = false;
// ENABLE/DISABLE Console Logs
if(!DEBUG){ 
  window.console.log = function() {}
}


const firebaseConfig = {
  apiKey: "AIzaSyAVbXaADg6Dtyk0l_vFcWEeZepIVlU1diU",
  authDomain: "digitaloffice-dc738.firebaseapp.com",
  projectId: "digitaloffice-dc738",
  storageBucket: "digitaloffice-dc738.appspot.com",
  messagingSenderId: "12747671299",
  appId: "1:12747671299:web:e00f897773d6d746f10b36",
  measurementId: "G-4WN3964WDJ"
};



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot(),
    AngularFirestoreModule,
    AngularFireModule.initializeApp(firebaseConfig),

    AppRoutingModule],
  providers: [
    StatusBar,
    DataProvider,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
