import { DataProvider } from './providers/data';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _DataProvider:DataProvider
  ) {
    this.initializeApp();

  }


  hiddenPrint(){
    this._DataProvider.printFlag = true;
  }
  



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    
      this._DataProvider.loadCityData("bat-yam").subscribe(x=>{
        this._DataProvider.YadLevnimData=x[0];
        //  console.log(this._DataProvider.YadLevnimData)
      });

      this._DataProvider.loadCasualtyData("bat-yam").subscribe(x=>{
      //  console.log(x);
        this._DataProvider.CasualtyData=x;
       //   console.log(this._DataProvider.CasualtyData);

          this._DataProvider.loopOnCasualtys(x);

          setInterval(() => {
            this._DataProvider.loopOnCasualtys(x); 
            }, 36000000);
          
      });




    });
  }
}
